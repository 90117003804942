import React, { useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Switch,
  Navigate,
  useLocation,
} from "react-router-dom";
import BasicPage from "../Pages/BasicPage";
import CreateNewLead from "../Pages/Leads/CreateNewLead";
import EditLead from "../Pages/Leads/EditLead";
import UnverifiedLeads from "../Pages/Leads/UnverifiedLeads";
import ViewLeads from "../Pages/Leads/ViewLeads";
import Dashboard from "../Pages/Dashboard";
import CreateNewLeadFrom from "../Pages/Leads/CreateNewLeadFrom";
import GetleadsfromBizooda from "../Pages/Leads/GetleadsfromBizooda";

import AddNewTask from "../Pages/Tasks/AddNewTask";

import Login from "../Pages/Login";

import AddDetails from "../Pages/AddDetails";
import Signup from "../Pages/Signup";
import AllTask from "../Pages/Tasks/AllTask";
import MyFunnels from "../Pages/Funnels/MyFunnels";

import AddCampaign from "../Pages/Marketing/AddCampaign";
import ViewCampaign from "../Pages/Marketing/ViewCampaign";
import BigCalendar from "../Pages/Tasks/BigCalendar";
import ArchiveLead from "../Pages/Leads/ArchiveLead";
import Pipeline from "../Pages/Leads/Pipeline";
import PremadeFunnels from "../Pages/Funnels/PremadeFunnels";
import FunnelPage1 from "../Pages/Funnels/FunnelPage1";
import FunnelPage2 from "../Pages/Funnels/FunnelPage2";
import FunnelPage3 from "../Pages/Funnels/FunnelPage3";
import Acitivity from "../Pages/Marketing/Acitivity";
import AddActivity from "../Pages/Marketing/AddActivity";
import VisitingCard from "../Pages/VisitingCard";
import { NotFoundPage } from "../Pages/not-found";
import AdminFunnel from "../Pages/Admin/FunnelAdmin";
import { LoginAdmin } from "../Pages/Admin/login";
import KastPreview from "../Pages/kast-preview/kast-preview";
import { ListingSection } from "../Pages/kast-preview/listing-section";
import { getScriptByUserID } from "../Redux/Services/service";
import { PublicFunnel } from "../Pages/Funnels/publicFunnel";
// import { LoginAdmin } from "../Pages/admin/login";

import Plausible from 'plausible-tracker'
const plausible = Plausible({
  domain: 'kast.biz',
  trackLocalhost: true,
  apiHost: "https://plausible.kast.biz",
  hashMode: true
})


const RedirectPage = () => {
  window.location.href = "https://app.kast.biz/";
  return <div></div>;
};

function Routing() {
  // const token=localStorage.getItem("user_token")
  const [login, setLogin] = useState(true);
  const [token, setToken] = useState(localStorage.getItem("user_token"));
  const server = "prod";
  const [scriptList, setScriptList] = useState([])



  React.useEffect(() => {

    plausible.trackPageview({
      url: window.location.href
    })
    plausible.trackEvent({
      url: window.location.href
    })
    plausible.enableAutoPageviews({
      url: window.location.href
    })

  }, [])


  return (
    <BrowserRouter>
      {server == "prod" ? (
        <Routes>
          <Route exact path="/" element={<RedirectPage />} />
          <Route exact path="/:card_id" element={<VisitingCard />} />


          <Route
            exact
            path="/:user_id/The Ultimate Home Seller Checklist"
            element={<FunnelPage1 />}
          />
          <Route
            exact
            path="/:user_id/Home Buying 101- The Ultimate Guide to Buying a Home"
            element={<FunnelPage2 />}
          />
          <Route
            exact
            path="/:user_id/Top 10 Mistakes To Avoid Before Buying a New House"
            element={<FunnelPage2 />}
          />
          <Route
            exact
            path="/:user_id/7 Insider Secrets To Selling Your Home"
            element={<FunnelPage3 />}
          />



          {/* with postcard id */}
          <Route
            exact
            path="/:user_id/SellingInsiderSecrets/:pid?"
            element={<FunnelPage3 />}
          />

          <Route
            exact
            path="/:user_id/HomeSellerChecklist/:pid?"
            element={<FunnelPage1 />}
          />
          <Route
            exact
            path="/:user_id/Buying101Guide/:pid?"
            element={<FunnelPage2 />}
          />
          <Route
            exact
            path="/:user_id/:link/:pid?"
            element={<MyFunnels />}
          />



          <Route
            exact
            path="/:userName/listing/:id"
            element={<ListingSection />}
          />


          <Route path="/loginAdminVipin1028936" element={<LoginAdmin />} />
          <Route exact path="/admin/Funnel"
            element={<AdminFunnel />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      ) : (
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="signup" element={<Signup />} />
          <Route exact path="add-details" element={<AddDetails />} />
          <Route exact path="/page" element={<BasicPage />}>
            <Route exact path="dashboard-overview" element={<Dashboard />} />
            <Route exact path="create-new-lead" element={<CreateNewLead />} />

            <Route
              exact
              path="create-new-lead/create"
              element={<CreateNewLeadFrom />}
            />

            <Route exact path="view-leads/edit" element={<EditLead />} />

            <Route exact path="view-leads" element={<ViewLeads />} />

            <Route exact path="archive-leads" element={<ArchiveLead />} />

            <Route exact path="pipeline" element={<Pipeline />} />

            <Route
              exact
              path="unverified-leads"
              element={<UnverifiedLeads />}
            />

            <Route
              exact
              path="get-leads-from-bizooda"
              element={<GetleadsfromBizooda />}
            />

            <Route exact path="add-new-task" element={<AddNewTask />} />

            <Route exact path="all-task" element={<AllTask />} />

            <Route exact path="task-calendar" element={<BigCalendar />} />

            <Route exact path="my-funnels" element={<MyFunnels />} />

            <Route exact path="premade-funnels" element={<PremadeFunnels />} />

            <Route exact path="add-campaign" element={<AddCampaign />} />

            <Route exact path="add-activity" element={<AddActivity />} />

            <Route exact path="view-campaign" element={<ViewCampaign />} />

            <Route exact path="activity" element={<Acitivity />} />
          </Route>
          <Route
            exact
            path="visiting-card/:card_id"
            element={<VisitingCard />}
          />
          <Route
            exact
            path="The Ultimate Home Seller Checklist"
            element={<FunnelPage1 />}
          />
          <Route
            exact
            path="Home Buying 101- The Ultimate Guide to Buying a Home"
            element={<FunnelPage2 />}
          />
          <Route
            exact
            path="Top 10 Mistakes To Avoid Before Buying a New House"
            element={<FunnelPage2 />}
          />
          <Route
            exact
            path="7 Insider Secrets To Selling Your Home"
            element={<FunnelPage3 />}
          />

          <Route
            exact
            path="The Ultimate Home Seller Checklist/:user_id"
            element={<FunnelPage1 />}
          />
          <Route
            exact
            path="Home Buying 101- The Ultimate Guide to Buying a Home/:user_id"
            element={<FunnelPage2 />}
          />
          <Route
            exact
            path="7 Insider Secrets To Selling Your Home/:user_id"
            element={<FunnelPage3 />}
          />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default Routing;
